import React from 'react'

const Login = () => {
  return (
    <div className='login'>
      <form>
        <input type="text" name="" id="" />
        <input type="password" name="" id="" />
        <button>Login</button>
      </form>
    </div>
  )
}

export default Login